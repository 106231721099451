import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styled from "styled-components";

import Card from "./Card";

import MakingMemories from "../svg/logo/Making_memories.svg";
import Check from "../svg/check.svg";
import Cross from "../svg/cross.svg";

import { device } from "../globalStyles";
import { useLangContext } from "../context/langContext";

const Common = styled.div<{
  index?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform linear 500ms;
  transition-delay: ${({ index = 0 }) => {
    // if ever in the case of a more than 3x3 matrix, can be calculated by converting array of index to a Matrix and summing i+j
    const delay = 150;

    if (index === 0) return 0;
    if ([1, 3].includes(index)) return 1 * delay + "ms";
    if ([2, 4, 6].includes(index)) return 2 * delay + "ms";
    if ([5, 7].includes(index)) return 3 * delay + "ms";
    if (index === 8) return 4 * delay + "ms";
  }};
`;

const Overlay = styled.div<{
  error?: boolean;
  forgotten?: boolean;
  gameOver?: boolean;
  wrongPic?: boolean;
  selected?: boolean;
}>`
  background: ${({ error, forgotten, gameOver }) => {
    if (gameOver) {
      if (error || forgotten) return "transparent";
      return `transparent`;
    } else {
      return `var(--dark-blue-3)`;
      // return `white`;
    }
  }};
  opacity: ${({ gameOver, selected }) => (gameOver ? (selected ? 1 : 0.3) : 1)};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: ${({ gameOver }) => (gameOver ? `none` : `solid 2px white`)};

  color: ${({ error, forgotten }) =>
    error || forgotten ? `white` : `var(--dark-blue-3)`};

  svg {
    path {
      stroke: ${({ gameOver, valid }) =>
        gameOver
          ? valid
            ? `var(--dark-blue-3)`
            : `var(--red)`
          : `var(--dark-blue-3)`};
    }
  }

  @media (${device.minMobileXL}) {
    border-radius: 4px;
  }
`;

const Front = styled(Common)`
  ${Overlay} {
    top: unset;
    height: unset;
    bottom: -2px;

    svg {
      width: 16px;
    }

    @media (${device.minMobileXL}) {
      bottom: 0;
      padding: 4px 0;
    }

    @media (${device.minTablet}) {
      padding: 2% 0;
      svg {
        width: unset;
      }
    }

    @media (${device.minMobileXL}) and (min-height: 890px) {
      padding: 10px 0;
    }
  }
`;

const GameCard = styled(Card)`
  max-height: 100%;
  max-width: 100%;
  //max-height: calc((100 * var(--vh) - 180px) / 3);
  img {
    width: 100%;
    //max-height: calc((100 * var(--vh) - 180px) / 3 - 26px);
  }

  // @media (${device.minMobileXL}) {
  //   max-height: 23vh;
  //   img {
  //     max-height: calc(23 * var(--vh) - 16px);
  //   }
  // }
`;

const StyledFrontCard = styled(GameCard)<{
  gameOver?: boolean;
  error?: boolean;
  forgotten?: boolean;
  wrongPic?: boolean;
  selected?: boolean;
}>`
  //background: white;
  background: ${({ gameOver, error, forgotten }) => {
    if (gameOver) {
      if (error || forgotten) return "white";
      return "white";
    } else {
      return "white";
    }
  }};

  opacity: ${({ gameOver, selected }) => (gameOver ? (selected ? 1 : 0.3) : 1)};
  border: 0;
`;

const Back = styled(Common)`
  position: absolute;
  top: 0;
  transform: rotateY(-180deg);
`;

const StyledBackCard = styled(GameCard)`
  cursor: pointer;
  background: var(--dark-blue-1);
  display: flex;
  align-items: center;
  padding: 8px;
  border: 2px solid white;

  svg {
    width: 80%;
    height: auto;
    margin: auto;

    path {
      fill: white;
    }
  }
`;

const Image = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  height: 100%;
  background-position: center;
`;

const RotationContainer = styled.div<{ gameOver: boolean; rotation: number }>`
  perspective: 1400px;
  position: relative;
  margin-bottom: 8px;
  transform: rotate(calc(${({ rotation }) => rotation} * 1deg));
  transform-style: preserve-3d;
  //aspect-ratio: 180/250;
  margin-left: 8px;
  margin-right: 8px;
  max-width: calc(100% / 3);

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &.timesUp {
    ${Front} {
      transform: rotateY(${({ gameOver }) => (gameOver ? 360 : 180)}deg);
    }
    ${Back} {
      transform: rotateY(${({ gameOver }) => (gameOver ? 180 : 0)}deg);
    }
  }

  transition: transform 0.2s var(--cubic-in-out);
  &:hover {
    transform: scale(1.05)
      rotate(calc(${({ rotation }) => rotation + 1} * 1deg));
  }

  @media (${device.minTablet}) {
    margin-bottom: 16px;
  }
`;

const RotatingCard = ({
  photo,
  selected,
  index,
  gameOver,
  timesUp,
  handleClick,
}) => {
  const error = selected && !photo.valid;
  const forgotten = photo.valid && !selected;
  const wrongPic = !photo.valid;
  const { lang } = useLangContext();

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    setRotation(Math.floor(Math.random() * 5) - 2);
  }, []);

  return (
    <RotationContainer
      key={photo.src}
      className={classNames({
        selected,
        timesUp,
      })}
      gameOver={gameOver}
      rotation={rotation}
    >
      <Front index={index}>
        <StyledFrontCard
          gameOver={gameOver}
          error={error}
          forgotten={forgotten}
          wrongPic={wrongPic}
          selected={selected}
          valid={photo.valid}
        >
          <Image src={photo.src} alt={photo.alt} />
        </StyledFrontCard>
        {gameOver && (
          <Overlay
            gameOver={gameOver}
            error={error}
            forgotten={forgotten}
            wrongPic={wrongPic}
            selected={selected}
            valid={photo.valid}
          >
            {/*{error || forgotten ? <Cross /> : <Check />}*/}
            {photo.valid ? <Check /> : <Cross />}
            {/*{selected && <Check />}*/}
            {/*{photo[lang]}*/}
          </Overlay>
        )}
      </Front>
      <Back
        role="checkbox"
        aria-checked={selected}
        onClick={() => handleClick(photo.id)}
        id={photo.id}
        index={index}
      >
        <StyledBackCard>
          {selected ? (
            <Overlay>
              <MakingMemories />
            </Overlay>
          ) : (
            <MakingMemories />
          )}
        </StyledBackCard>
      </Back>
    </RotationContainer>
  );
};

export default RotatingCard;
