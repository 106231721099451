import CUR01 from "../images/curacao/img-cur-01.webp";
import CUR02 from "../images/curacao/img-cur-02.webp";
import CUR03 from "../images/curacao/img-cur-03.webp";
import CUR04 from "../images/curacao/img-cur-04.webp";
import CUR05 from "../images/curacao/img-cur-05.webp";
import CUR06 from "../images/curacao/img-cur-06.webp";
import CUR07 from "../images/curacao/img-cur-07.webp";

// prettier-ignore
const CURACAO_PHOTOS = [
  { src: CUR01, valid: true, en: 'Curaçao', nl: 'Curaçao', fr: 'Curaçao', cityCode: 'CUR', alt: "Waves on rocks", id: 1 },
  { src: CUR02, valid: true, en: 'Curaçao', nl: 'Curaçao', fr: 'Curaçao', cityCode: 'CUR', alt: "Beach seats under a palm tree", id: 2 },
  { src: CUR03, valid: true, en: 'Curaçao', nl: 'Curaçao', fr: 'Curaçao', cityCode: 'CUR', alt: "Willemstad corner", id: 3 },
  { src: CUR04, valid: true, en: 'Curaçao', nl: 'Curaçao', fr: 'Curaçao', cityCode: 'CUR', alt: "Willemstad downtown", id: 4 },
  { src: CUR05, valid: true, en: 'Curaçao', nl: 'Curaçao', fr: 'Curaçao', cityCode: 'CUR', alt: "Lighthouse", id: 5 },
  { src: CUR06, valid: true, en: 'Curaçao', nl: 'Curaçao', fr: 'Curaçao', cityCode: 'CUR', alt: "Iguana", id: 6 },
  { src: CUR07, valid: true, en: 'Curaçao', nl: 'Curaçao', fr: 'Curaçao', cityCode: 'CUR', alt: "Bon bini seaside resort", id: 7 },
];

export default CURACAO_PHOTOS;
