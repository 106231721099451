import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";

import Layout from "../components/Layout";
import CardGame from "../components/CardGame";
import MovingBackground from "../components/MovingBackground";

import { useFormContext } from "../context/formContext";
import { useCountryContext } from "../context/countryContext";
import { useGeolocationContext } from "../context/geolocationContext";
import { useLangContext } from "../context/langContext";

const StyledLayout = styled(Layout)`
  background: var(--blue);
  color: white;
`;

const Content = styled.div`
  position: relative;
  //display: flex;
  //flex-direction: column;
  padding: 0 12px;
  height: calc(100 * var(--vh) - var(--header-height));
`;

export const DURATION = 15;

const PlayPage = () => {
  const [gameOver, setGameOver] = useState(false);
  const [timerCanStart, setTimerCanStart] = useState(false);
  const backBackgroundRef = useRef(null);
  const frontBackgroundRef = useRef(null);
  const animatedInRef = useRef(false);

  const { form } = useFormContext();
  const { country } = useCountryContext();
  const { geolocation } = useGeolocationContext();
  const { lang } = useLangContext();

  function animateIn() {
    if (
      backBackgroundRef.current &&
      frontBackgroundRef.current &&
      !animatedInRef.current
    ) {
      animatedInRef.current = true;
      const tl = gsap.timeline();
      tl.add(
        frontBackgroundRef.current.revealFromLeft(0.5, {
          onComplete: () => setTimerCanStart(true),
        })
      ).add(backBackgroundRef.current.swipeRight(DURATION, 1));
    }
  }

  useEffect(() => {
    if (gameOver && backBackgroundRef.current) {
      handleSubmit().then(() => {
        backBackgroundRef.current.swipeRight(0.5);
      });
    }
  }, [gameOver]);

  const handleSubmit = async () => {
    if (country === "nl") return;
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "https://cloud.klm-mail.com/klmnl-email-api?action=emailLeadBelux&",
      true
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(
      JSON.stringify({
        ...form,
        country,
        geolocation,
        language: lang,
      })
    );

    xhr.onerror = function (this, error) {
      console.error("this error", this);
      console.error("event error", error);
    };
  };

  return (
    <StyledLayout title="Play" transparent pageName="Game">
      <MovingBackground
        ref={backBackgroundRef}
        onAngleInitialised={animateIn}
      />
      <Content>
        <CardGame
          onComplete={() => setGameOver(true)}
          timerCanStart={timerCanStart}
        />
      </Content>
      <MovingBackground ref={frontBackgroundRef} className="fullScreen" />
    </StyledLayout>
  );
};

export default PlayPage;
