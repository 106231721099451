import LIS01 from "../images/lisbon/img-lis-01.webp";
import LIS02 from "../images/lisbon/img-lis-02.webp";
import LIS03 from "../images/lisbon/img-lis-03.webp";
import LIS04 from "../images/lisbon/img-lis-04.webp";
import LIS05 from "../images/lisbon/img-lis-05.webp";
import LIS06 from "../images/lisbon/img-lis-06.webp";
import LIS07 from "../images/lisbon/img-lis-07.webp";

// prettier-ignore
const LISBON_PHOTOS = [
  { src: LIS01, valid: true, en: "Lisbon", nl: "Lissabon", fr: "Lisbonne", cityCode: 'LIS', alt: "Belém tower", id: 1 },
  { src: LIS02, valid: true, en: "Lisbon", nl: "Lissabon", fr: "Lisbonne", cityCode: 'LIS', alt: "Vasco da Gama Bridge", id: 2 },
  { src: LIS03, valid: true, en: "Lisbon", nl: "Lissabon", fr: "Lisbonne", cityCode: 'LIS', alt: "Alfama neighborhood", id: 3 },
  { src: LIS04, valid: true, en: "Lisbon", nl: "Lissabon", fr: "Lisbonne", cityCode: 'LIS', alt: "Praça do Comércio", id: 4 },
  { src: LIS05, valid: true, en: "Lisbon", nl: "Lissabon", fr: "Lisbonne", cityCode: 'LIS', alt: "Tramway", id: 5 },
  { src: LIS06, valid: true, en: "Lisbon", nl: "Lissabon", fr: "Lisbonne", cityCode: 'LIS', alt: "Museum of Art, Architecture and Technology", id: 6,},
  { src: LIS07, valid: true, en: "Lisbon", nl: "Lissabon", fr: "Lisbonne", cityCode: 'LIS', alt: "Pasteis de Nata", id: 7 },
];

export default LISBON_PHOTOS;
