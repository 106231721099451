import NYC01 from "../images/new-york/img-nyc-01.webp";
import NYC02 from "../images/new-york/img-nyc-02.webp";
import NYC03 from "../images/new-york/img-nyc-03.webp";
import NYC04 from "../images/new-york/img-nyc-04.webp";
import NYC05 from "../images/new-york/img-nyc-05.webp";
import NYC06 from "../images/new-york/img-nyc-06.webp";
import NYC07 from "../images/new-york/img-nyc-07.webp";

// prettier-ignore
const NEW_YORK_PHOTOS = [
  { src: NYC01, valid: true, en: "New York City", nl: "New York", fr: "New York", cityCode: 'NYC', alt: "ice skate ring in central park", id: 1 },
  { src: NYC02, valid: true, en: "New York City", nl: "New York", fr: "New York", cityCode: 'NYC', alt: "time square", id: 2 },
  { src: NYC03, valid: true, en: "New York City", nl: "New York", fr: "New York", cityCode: 'NYC', alt: "Yankees stadium", id: 3 },
  { src: NYC04, valid: true, en: "New York City", nl: "New York", fr: "New York", cityCode: 'NYC', alt: "statue of liberty", id: 4 },
  { src: NYC05, valid: true, en: "New York City", nl: "New York", fr: "New York", cityCode: 'NYC', alt: "hamburger", id: 5 },
  { src: NYC06, valid: true, en: "New York City", nl: "New York", fr: "New York", cityCode: 'NYC', alt: "yellow cab", id: 6 },
  { src: NYC07, valid: true, en: "New York City", nl: "New York", fr: "New York", cityCode: 'NYC', alt: "brooklyn bridge", id: 7 },
];

export default NEW_YORK_PHOTOS;
